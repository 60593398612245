// Color Codes
$white: #fff;
$black: #000;
$blue: #227dd0;
// Variables
$primary: #210207;
$secondary: #d74761;
$success: #00b860;
$light: #f1f0f0;
$gray-c6: #c6c4c5;
