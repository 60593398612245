@import "../../scss/variables";
.sidebarNav {
  .sidebarLink {
    width: 100%;
    text-decoration: none;
    position: relative;
    padding-left: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    &.active {
      .sibarItem {
        background-color: rgba($color: $secondary, $alpha: 0.1);
        border-radius: 8px 0px 0px 8px;
        -webkit-border-radius: 8px 0px 0px 8px;
        -moz-border-radius: 8px 0px 0px 8px;
        -ms-border-radius: 8px 0px 0px 8px;
        -o-border-radius: 8px 0px 0px 8px;
      }
      &::before {
        content: "";
        width: 5px;
        height: calc(100% - 20px);
        background-color: $secondary;
        display: block;
        position: absolute;
        left: 0px;
        border-radius: 0px 8px 8px 0px;
        -webkit-border-radius: 0px 8px 8px 0px;
        -moz-border-radius: 0px 8px 8px 0px;
        -ms-border-radius: 0px 8px 8px 0px;
        -o-border-radius: 0px 8px 8px 0px;
      }
    }
  }
}
