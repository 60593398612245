.fi {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  line-height: 1;
  &::before {
    content: "\00a0";
  }
}

.fi-xx {
  background-image: url("../assets/images/flags/xx.svg");
}

.fi-ad {
  background-image: url("../assets/images/flags/ad.svg");
}

.fi-ae {
  background-image: url("../assets/images/flags/ae.svg");
}

.fi-af {
  background-image: url("../assets/images/flags/af.svg");
}

.fi-ag {
  background-image: url("../assets/images/flags/ag.svg");
}

.fi-ai {
  background-image: url("../assets/images/flags/ai.svg");
}

.fi-al {
  background-image: url("../assets/images/flags/al.svg");
}

.fi-am {
  background-image: url("../assets/images/flags/am.svg");
}

.fi-ao {
  background-image: url("../assets/images/flags/ao.svg");
}

.fi-aq {
  background-image: url("../assets/images/flags/aq.svg");
}

.fi-ar {
  background-image: url("../assets/images/flags/ar.svg");
}

.fi-as {
  background-image: url("../assets/images/flags/as.svg");
}

.fi-at {
  background-image: url("../assets/images/flags/at.svg");
}

.fi-au {
  background-image: url("../assets/images/flags/au.svg");
}

.fi-aw {
  background-image: url("../assets/images/flags/aw.svg");
}

.fi-ax {
  background-image: url("../assets/images/flags/ax.svg");
}

.fi-az {
  background-image: url("../assets/images/flags/az.svg");
}

.fi-ba {
  background-image: url("../assets/images/flags/ba.svg");
}

.fi-bb {
  background-image: url("../assets/images/flags/bb.svg");
}

.fi-bd {
  background-image: url("../assets/images/flags/bd.svg");
}

.fi-be {
  background-image: url("../assets/images/flags/be.svg");
}

.fi-bf {
  background-image: url("../assets/images/flags/bf.svg");
}

.fi-bg {
  background-image: url("../assets/images/flags/bg.svg");
}

.fi-bh {
  background-image: url("../assets/images/flags/bh.svg");
}

.fi-bi {
  background-image: url("../assets/images/flags/bi.svg");
}

.fi-bj {
  background-image: url("../assets/images/flags/bj.svg");
}

.fi-bl {
  background-image: url("../assets/images/flags/bl.svg");
}

.fi-bm {
  background-image: url("../assets/images/flags/bm.svg");
}

.fi-bn {
  background-image: url("../assets/images/flags/bn.svg");
}

.fi-bo {
  background-image: url("../assets/images/flags/bo.svg");
}

.fi-bq {
  background-image: url("../assets/images/flags/bq.svg");
}

.fi-br {
  background-image: url("../assets/images/flags/br.svg");
}

.fi-bs {
  background-image: url("../assets/images/flags/bs.svg");
}

.fi-bt {
  background-image: url("../assets/images/flags/bt.svg");
}

.fi-bv {
  background-image: url("../assets/images/flags/bv.svg");
}

.fi-bw {
  background-image: url("../assets/images/flags/bw.svg");
}

.fi-by {
  background-image: url("../assets/images/flags/by.svg");
}

.fi-bz {
  background-image: url("../assets/images/flags/bz.svg");
}

.fi-ca {
  background-image: url("../assets/images/flags/ca.svg");
}

.fi-cc {
  background-image: url("../assets/images/flags/cc.svg");
}

.fi-cd {
  background-image: url("../assets/images/flags/cd.svg");
}

.fi-cf {
  background-image: url("../assets/images/flags/cf.svg");
}

.fi-cg {
  background-image: url("../assets/images/flags/cg.svg");
}

.fi-ch {
  background-image: url("../assets/images/flags/ch.svg");
}

.fi-ci {
  background-image: url("../assets/images/flags/ci.svg");
}

.fi-ck {
  background-image: url("../assets/images/flags/ck.svg");
}

.fi-cl {
  background-image: url("../assets/images/flags/cl.svg");
}

.fi-cm {
  background-image: url("../assets/images/flags/cm.svg");
}

.fi-cn {
  background-image: url("../assets/images/flags/cn.svg");
}

.fi-co {
  background-image: url("../assets/images/flags/co.svg");
}

.fi-cr {
  background-image: url("../assets/images/flags/cr.svg");
}

.fi-cu {
  background-image: url("../assets/images/flags/cu.svg");
}

.fi-cv {
  background-image: url("../assets/images/flags/cv.svg");
}

.fi-cw {
  background-image: url("../assets/images/flags/cw.svg");
}

.fi-cx {
  background-image: url("../assets/images/flags/cx.svg");
}

.fi-cy {
  background-image: url("../assets/images/flags/cy.svg");
}

.fi-cz {
  background-image: url("../assets/images/flags/cz.svg");
}

.fi-de {
  background-image: url("../assets/images/flags/de.svg");
}

.fi-dj {
  background-image: url("../assets/images/flags/dj.svg");
}

.fi-dk {
  background-image: url("../assets/images/flags/dk.svg");
}

.fi-dm {
  background-image: url("../assets/images/flags/dm.svg");
}

.fi-do {
  background-image: url("../assets/images/flags/do.svg");
}

.fi-dz {
  background-image: url("../assets/images/flags/dz.svg");
}

.fi-ec {
  background-image: url("../assets/images/flags/ec.svg");
}

.fi-ee {
  background-image: url("../assets/images/flags/ee.svg");
}

.fi-eg {
  background-image: url("../assets/images/flags/eg.svg");
}

.fi-eh {
  background-image: url("../assets/images/flags/eh.svg");
}

.fi-er {
  background-image: url("../assets/images/flags/er.svg");
}

.fi-es {
  background-image: url("../assets/images/flags/es.svg");
}

.fi-et {
  background-image: url("../assets/images/flags/et.svg");
}

.fi-fi {
  background-image: url("../assets/images/flags/fi.svg");
}

.fi-fj {
  background-image: url("../assets/images/flags/fj.svg");
}

.fi-fk {
  background-image: url("../assets/images/flags/fk.svg");
}

.fi-fm {
  background-image: url("../assets/images/flags/fm.svg");
}

.fi-fo {
  background-image: url("../assets/images/flags/fo.svg");
}

.fi-fr {
  background-image: url("../assets/images/flags/fr.svg");
}

.fi-ga {
  background-image: url("../assets/images/flags/ga.svg");
}

.fi-gb {
  background-image: url("../assets/images/flags/gb.svg");
}

.fi-gd {
  background-image: url("../assets/images/flags/gd.svg");
}

.fi-ge {
  background-image: url("../assets/images/flags/ge.svg");
}

.fi-gf {
  background-image: url("../assets/images/flags/gf.svg");
}

.fi-gg {
  background-image: url("../assets/images/flags/gg.svg");
}

.fi-gh {
  background-image: url("../assets/images/flags/gh.svg");
}

.fi-gi {
  background-image: url("../assets/images/flags/gi.svg");
}

.fi-gl {
  background-image: url("../assets/images/flags/gl.svg");
}

.fi-gm {
  background-image: url("../assets/images/flags/gm.svg");
}

.fi-gn {
  background-image: url("../assets/images/flags/gn.svg");
}

.fi-gp {
  background-image: url("../assets/images/flags/gp.svg");
}

.fi-gq {
  background-image: url("../assets/images/flags/gq.svg");
}

.fi-gr {
  background-image: url("../assets/images/flags/gr.svg");
}

.fi-gs {
  background-image: url("../assets/images/flags/gs.svg");
}

.fi-gt {
  background-image: url("../assets/images/flags/gt.svg");
}

.fi-gu {
  background-image: url("../assets/images/flags/gu.svg");
}

.fi-gw {
  background-image: url("../assets/images/flags/gw.svg");
}

.fi-gy {
  background-image: url("../assets/images/flags/gy.svg");
}

.fi-hk {
  background-image: url("../assets/images/flags/hk.svg");
}

.fi-hm {
  background-image: url("../assets/images/flags/hm.svg");
}

.fi-hn {
  background-image: url("../assets/images/flags/hn.svg");
}

.fi-hr {
  background-image: url("../assets/images/flags/hr.svg");
}

.fi-ht {
  background-image: url("../assets/images/flags/ht.svg");
}

.fi-hu {
  background-image: url("../assets/images/flags/hu.svg");
}

.fi-id {
  background-image: url("../assets/images/flags/id.svg");
}

.fi-ie {
  background-image: url("../assets/images/flags/ie.svg");
}

.fi-il {
  background-image: url("../assets/images/flags/il.svg");
}

.fi-im {
  background-image: url("../assets/images/flags/im.svg");
}

.fi-in {
  background-image: url("../assets/images/flags/in.svg");
}

.fi-io {
  background-image: url("../assets/images/flags/io.svg");
}

.fi-iq {
  background-image: url("../assets/images/flags/iq.svg");
}

.fi-ir {
  background-image: url("../assets/images/flags/ir.svg");
}

.fi-is {
  background-image: url("../assets/images/flags/is.svg");
}

.fi-it {
  background-image: url("../assets/images/flags/it.svg");
}

.fi-je {
  background-image: url("../assets/images/flags/je.svg");
}

.fi-jm {
  background-image: url("../assets/images/flags/jm.svg");
}

.fi-jo {
  background-image: url("../assets/images/flags/jo.svg");
}

.fi-jp {
  background-image: url("../assets/images/flags/jp.svg");
}

.fi-ke {
  background-image: url("../assets/images/flags/ke.svg");
}

.fi-kg {
  background-image: url("../assets/images/flags/kg.svg");
}

.fi-kh {
  background-image: url("../assets/images/flags/kh.svg");
}

.fi-ki {
  background-image: url("../assets/images/flags/ki.svg");
}

.fi-km {
  background-image: url("../assets/images/flags/km.svg");
}

.fi-kn {
  background-image: url("../assets/images/flags/kn.svg");
}

.fi-kp {
  background-image: url("../assets/images/flags/kp.svg");
}

.fi-kr {
  background-image: url("../assets/images/flags/kr.svg");
}

.fi-kw {
  background-image: url("../assets/images/flags/kw.svg");
}

.fi-ky {
  background-image: url("../assets/images/flags/ky.svg");
}

.fi-kz {
  background-image: url("../assets/images/flags/kz.svg");
}

.fi-la {
  background-image: url("../assets/images/flags/la.svg");
}

.fi-lb {
  background-image: url("../assets/images/flags/lb.svg");
}

.fi-lc {
  background-image: url("../assets/images/flags/lc.svg");
}

.fi-li {
  background-image: url("../assets/images/flags/li.svg");
}

.fi-lk {
  background-image: url("../assets/images/flags/lk.svg");
}

.fi-lr {
  background-image: url("../assets/images/flags/lr.svg");
}

.fi-ls {
  background-image: url("../assets/images/flags/ls.svg");
}

.fi-lt {
  background-image: url("../assets/images/flags/lt.svg");
}

.fi-lu {
  background-image: url("../assets/images/flags/lu.svg");
}

.fi-lv {
  background-image: url("../assets/images/flags/lv.svg");
}

.fi-ly {
  background-image: url("../assets/images/flags/ly.svg");
}

.fi-ma {
  background-image: url("../assets/images/flags/ma.svg");
}

.fi-mc {
  background-image: url("../assets/images/flags/mc.svg");
}

.fi-md {
  background-image: url("../assets/images/flags/md.svg");
}

.fi-me {
  background-image: url("../assets/images/flags/me.svg");
}

.fi-mf {
  background-image: url("../assets/images/flags/mf.svg");
}

.fi-mg {
  background-image: url("../assets/images/flags/mg.svg");
}

.fi-mh {
  background-image: url("../assets/images/flags/mh.svg");
}

.fi-mk {
  background-image: url("../assets/images/flags/mk.svg");
}

.fi-ml {
  background-image: url("../assets/images/flags/ml.svg");
}

.fi-mm {
  background-image: url("../assets/images/flags/mm.svg");
}

.fi-mn {
  background-image: url("../assets/images/flags/mn.svg");
}

.fi-mo {
  background-image: url("../assets/images/flags/mo.svg");
}

.fi-mp {
  background-image: url("../assets/images/flags/mp.svg");
}

.fi-mq {
  background-image: url("../assets/images/flags/mq.svg");
}

.fi-mr {
  background-image: url("../assets/images/flags/mr.svg");
}

.fi-ms {
  background-image: url("../assets/images/flags/ms.svg");
}

.fi-mt {
  background-image: url("../assets/images/flags/mt.svg");
}

.fi-mu {
  background-image: url("../assets/images/flags/mu.svg");
}

.fi-mv {
  background-image: url("../assets/images/flags/mv.svg");
}

.fi-mw {
  background-image: url("../assets/images/flags/mw.svg");
}

.fi-mx {
  background-image: url("../assets/images/flags/mx.svg");
}

.fi-my {
  background-image: url("../assets/images/flags/my.svg");
}

.fi-mz {
  background-image: url("../assets/images/flags/mz.svg");
}

.fi-na {
  background-image: url("../assets/images/flags/na.svg");
}

.fi-nc {
  background-image: url("../assets/images/flags/nc.svg");
}

.fi-ne {
  background-image: url("../assets/images/flags/ne.svg");
}

.fi-nf {
  background-image: url("../assets/images/flags/nf.svg");
}

.fi-ng {
  background-image: url("../assets/images/flags/ng.svg");
}

.fi-ni {
  background-image: url("../assets/images/flags/ni.svg");
}

.fi-nl {
  background-image: url("../assets/images/flags/nl.svg");
}

.fi-no {
  background-image: url("../assets/images/flags/no.svg");
}

.fi-np {
  background-image: url("../assets/images/flags/np.svg");
}

.fi-nr {
  background-image: url("../assets/images/flags/nr.svg");
}

.fi-nu {
  background-image: url("../assets/images/flags/nu.svg");
}

.fi-nz {
  background-image: url("../assets/images/flags/nz.svg");
}

.fi-om {
  background-image: url("../assets/images/flags/om.svg");
}

.fi-pa {
  background-image: url("../assets/images/flags/pa.svg");
}

.fi-pe {
  background-image: url("../assets/images/flags/pe.svg");
}

.fi-pf {
  background-image: url("../assets/images/flags/pf.svg");
}

.fi-pg {
  background-image: url("../assets/images/flags/pg.svg");
}

.fi-ph {
  background-image: url("../assets/images/flags/ph.svg");
}

.fi-pk {
  background-image: url("../assets/images/flags/pk.svg");
}

.fi-pl {
  background-image: url("../assets/images/flags/pl.svg");
}

.fi-pm {
  background-image: url("../assets/images/flags/pm.svg");
}

.fi-pn {
  background-image: url("../assets/images/flags/pn.svg");
}

.fi-pr {
  background-image: url("../assets/images/flags/pr.svg");
}

.fi-ps {
  background-image: url("../assets/images/flags/ps.svg");
}

.fi-pt {
  background-image: url("../assets/images/flags/pt.svg");
}

.fi-pw {
  background-image: url("../assets/images/flags/pw.svg");
}

.fi-py {
  background-image: url("../assets/images/flags/py.svg");
}

.fi-qa {
  background-image: url("../assets/images/flags/qa.svg");
}

.fi-re {
  background-image: url("../assets/images/flags/re.svg");
}

.fi-ro {
  background-image: url("../assets/images/flags/ro.svg");
}

.fi-rs {
  background-image: url("../assets/images/flags/rs.svg");
}

.fi-ru {
  background-image: url("../assets/images/flags/ru.svg");
}

.fi-rw {
  background-image: url("../assets/images/flags/rw.svg");
}

.fi-sa {
  background-image: url("../assets/images/flags/sa.svg");
}

.fi-sb {
  background-image: url("../assets/images/flags/sb.svg");
}

.fi-sc {
  background-image: url("../assets/images/flags/sc.svg");
}

.fi-sd {
  background-image: url("../assets/images/flags/sd.svg");
}

.fi-se {
  background-image: url("../assets/images/flags/se.svg");
}

.fi-sg {
  background-image: url("../assets/images/flags/sg.svg");
}

.fi-sh {
  background-image: url("../assets/images/flags/sh.svg");
}

.fi-si {
  background-image: url("../assets/images/flags/si.svg");
}

.fi-sj {
  background-image: url("../assets/images/flags/sj.svg");
}

.fi-sk {
  background-image: url("../assets/images/flags/sk.svg");
}

.fi-sl {
  background-image: url("../assets/images/flags/sl.svg");
}

.fi-sm {
  background-image: url("../assets/images/flags/sm.svg");
}

.fi-sn {
  background-image: url("../assets/images/flags/sn.svg");
}

.fi-so {
  background-image: url("../assets/images/flags/so.svg");
}

.fi-sr {
  background-image: url("../assets/images/flags/sr.svg");
}

.fi-ss {
  background-image: url("../assets/images/flags/ss.svg");
}

.fi-st {
  background-image: url("../assets/images/flags/st.svg");
}

.fi-sv {
  background-image: url("../assets/images/flags/sv.svg");
}

.fi-sx {
  background-image: url("../assets/images/flags/sx.svg");
}

.fi-sy {
  background-image: url("../assets/images/flags/sy.svg");
}

.fi-sz {
  background-image: url("../assets/images/flags/sz.svg");
}

.fi-tc {
  background-image: url("../assets/images/flags/tc.svg");
}

.fi-td {
  background-image: url("../assets/images/flags/td.svg");
}

.fi-tf {
  background-image: url("../assets/images/flags/tf.svg");
}

.fi-tg {
  background-image: url("../assets/images/flags/tg.svg");
}

.fi-th {
  background-image: url("../assets/images/flags/th.svg");
}

.fi-tj {
  background-image: url("../assets/images/flags/tj.svg");
}

.fi-tk {
  background-image: url("../assets/images/flags/tk.svg");
}

.fi-tl {
  background-image: url("../assets/images/flags/tl.svg");
}

.fi-tm {
  background-image: url("../assets/images/flags/tm.svg");
}

.fi-tn {
  background-image: url("../assets/images/flags/tn.svg");
}

.fi-to {
  background-image: url("../assets/images/flags/to.svg");
}

.fi-tr {
  background-image: url("../assets/images/flags/tr.svg");
}

.fi-tt {
  background-image: url("../assets/images/flags/tt.svg");
}

.fi-tv {
  background-image: url("../assets/images/flags/tv.svg");
}

.fi-tw {
  background-image: url("../assets/images/flags/tw.svg");
}

.fi-tz {
  background-image: url("../assets/images/flags/tz.svg");
}

.fi-ua {
  background-image: url("../assets/images/flags/ua.svg");
}

.fi-ug {
  background-image: url("../assets/images/flags/ug.svg");
}

.fi-um {
  background-image: url("../assets/images/flags/um.svg");
}

.fi-us {
  background-image: url("../assets/images/flags/us.svg");
}

.fi-uy {
  background-image: url("../assets/images/flags/uy.svg");
}

.fi-uz {
  background-image: url("../assets/images/flags/uz.svg");
}

.fi-va {
  background-image: url("../assets/images/flags/va.svg");
}

.fi-vc {
  background-image: url("../assets/images/flags/vc.svg");
}

.fi-ve {
  background-image: url("../assets/images/flags/ve.svg");
}

.fi-vg {
  background-image: url("../assets/images/flags/vg.svg");
}

.fi-vi {
  background-image: url("../assets/images/flags/vi.svg");
}

.fi-vn {
  background-image: url("../assets/images/flags/vn.svg");
}

.fi-vu {
  background-image: url("../assets/images/flags/vu.svg");
}

.fi-wf {
  background-image: url("../assets/images/flags/wf.svg");
}

.fi-ws {
  background-image: url("../assets/images/flags/ws.svg");
}

.fi-ye {
  background-image: url("../assets/images/flags/ye.svg");
}

.fi-yt {
  background-image: url("../assets/images/flags/yt.svg");
}

.fi-za {
  background-image: url("../assets/images/flags/za.svg");
}

.fi-zm {
  background-image: url("../assets/images/flags/zm.svg");
}

.fi-zw {
  background-image: url("../assets/images/flags/zw.svg");
}

.fi-ac {
  background-image: url("../assets/images/flags/ac.svg");
}

.fi-cp {
  background-image: url("../assets/images/flags/cp.svg");
}

.fi-dg {
  background-image: url("../assets/images/flags/dg.svg");
}

.fi-ea {
  background-image: url("../assets/images/flags/ea.svg");
}

.fi-es-ct {
  background-image: url("../assets/images/flags/es-ct.svg");
}

.fi-es-ga {
  background-image: url("../assets/images/flags/es-ga.svg");
}

.fi-eu {
  background-image: url("../assets/images/flags/eu.svg");
}

.fi-gb-eng {
  background-image: url("../assets/images/flags/gb-eng.svg");
}

.fi-gb-nir {
  background-image: url("../assets/images/flags/gb-nir.svg");
}

.fi-gb-sct {
  background-image: url("../assets/images/flags/gb-sct.svg");
}

.fi-gb-wls {
  background-image: url("../assets/images/flags/gb-wls.svg");
}

.fi-ic {
  background-image: url("../assets/images/flags/ic.svg");
}

.fi-ta {
  background-image: url("../assets/images/flags/ta.svg");
}

.fi-un {
  background-image: url("../assets/images/flags/un.svg");
}

.fi-xk {
  background-image: url("../assets/images/flags/xk.svg");
}
