@import "../../scss/variables";

@mixin pkgStyling($pkgColor) {
  .recomendedTag {
    background-color: $pkgColor;
  }
  .pkgname {
    color: $pkgColor;
  }
  .pkgTag {
    background-color: rgba($color: $pkgColor, $alpha: 0.1);
    color: $pkgColor;
  }
}
.package {
  &.blue {
    @include pkgStyling($blue);
  }
  &.secondary {
    @include pkgStyling($secondary);
  }
  &.green {
    @include pkgStyling($success);
  }
}
