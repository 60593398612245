@import "./scss/font";
@import "./scss/variables";
@import "./scss/bootstrap";
@import "./scss/flag";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}

html {
  font-size: 100%;
}

.ReactInputVerificationCode__item {
  --ReactInputVerificationCode-itemHeight: 70px;
  width: 100% !important;
  aspect-ratio: 8/9 !important;
  max-width: 70px !important;
  max-height: 80px !important;
}

.ReactInputVerificationCode__container{
  max-width: 100%;
}

body {
  background-color: $light;
  scroll-behavior: smooth;
}

// Font Sizes
h1,
.h1 {
  font-size: 3.5rem;
  font-weight: 600;
}

.h2,
h2 {
  font-size: 2.5rem;
  font-weight: 600;
}

.h3,
h3 {
  font-size: 2rem;
  font-weight: 700;
}

h4,
.h4 {
  font-size: 1.5rem;
  font-weight: 700;
}

h5,
.h5 {
  font-size: 1.25rem;
  font-weight: 700;
}
h6,
.h6 {
  font-size: 1.15rem;
  font-weight: 600;
}

p,
.paragraph {
  font-size: 1rem;
}

.fs-14px {
  font-size: 14px;
}

.fs-12px {
  font-size: 12px;
}

.fs-10px {
  font-size: 10px;
}

.fs-8px {
  font-size: 8px;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

// Bg Colors
.bg-white-10 {
  background-color: rgba($color: $white, $alpha: 0.1);
}

.bg-secondary-10 {
  background-color: rgba($color: $secondary, $alpha: 0.1);
}
.bg-blue {
  background-color: $blue;
}
.bg-green {
  background-color: $success;
}

.bg- .gray-c6 {
  background-color: $gray-c6;
}
.bg-pattren-img {
  background-image: var(--background);
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
}
// Width
.mw-70px {
  max-width: 70px;
}
.mw-100px {
  max-width: 100px;
}
.mw-150px {
  max-width: 150px;
}

.mw-200px {
  max-width: 200px;
}
.mw-250px {
  max-width: 250px;
}
.mw-300px {
  max-width: 300px;
}

.mw-400px {
  max-width: 400px;
}
.w-50px {
  width: 50px;
}
.w-40 {
  width: 40px;
}

.w-32 {
  width: 2rem;
}

.w-24 {
  width: 1.5rem;
}

.w-20 {
  width: 20px;
}

// modal width
.modal-90w {
  width: 90%;
  max-width: none !important;
}

// Height
.h-100px {
  height: 100px;
}
.h-50px {
  height: 50px;
}
.h-40 {
  height: 40px;
}

.h-32 {
  height: 2rem;
}

.h-20 {
  height: 20px;
}

.min-h-100 {
  min-height: 100vh;
}

.mh-250px {
  max-height: 250px;
}
.mh-50px {
  max-height: 50px;
}
.mh-100px {
  max-height: 100px;
}
.min-h-75px {
  min-height: 75px;
}
.min-h-100px {
  min-height: 100px;
}
.min-h-150px {
  min-height: 150px;
}
.min-h-200px {
  min-height: 200px;
}

.mh-150px {
  max-height: 150px;
}


// Ratio
.ratio-1x1 {
  aspect-ratio: 1/1;
}

.ratio-16x9 {
  aspect-ratio: 16/9;
}

.cursor-pointer {
  cursor: pointer;
}
// Modal
.modalTransparent {
  .modal-content {
    background: transparent;
    border: none;
  }
}

// Object
.obj-fit-cover {
  object-fit: cover;
}

.obj-fit-contain {
  object-fit: contain;
}

.obj-position-center {
  object-position: center;
}

// Form
.placeholder-white {
  &::placeholder {
    color: $white;
  }
}

// Dropdown
.dropdown {
  .dropdown-toggle {
    display: flex;
    align-items: center;
    &::after {
      background-color: transparent;
      width: 16px;
      height: 16px;
      border: none;
      background-image: url("./assets/images/icons/down-arrow.svg");
      background-position: center;
      background-size: 13px;
      background-repeat: no-repeat;
      margin-left: 0.5rem;
      position: relative;
    }
  }
  .dropdown-item {
    &.active,
    &:active {
      background-color: #e9ecef;
      color: #1e2125;
    }
  }
}

// Upload Section
.uploadSection {
  border: 1px dashed $secondary;
  background-color: rgba($color: $secondary, $alpha: 0.07);
  min-height: 100px;
  &.success {
    border-color: $success;
    background-color: rgba($color: $success, $alpha: 0.1);
  }
}

.radioPaymentCard {
  position: relative;
  padding: 0 !important;
  margin: 0 !important;
  input {
    &[type="radio"] {
      display: none;
    }
  }
  label {
    width: 100%;
    height: 100%;
  }
  input:checked + label {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &::after {
      content: "";
      width: 30px;
      height: 30px;
      background: url("./assets/images/icons/tick-white-circle.svg");
      background-position: center;
      background-size: 30px 30px;
      background-repeat: no-repeat;
      position: absolute;
      top: -5px;
      right: -5px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
    }
  }
}

// Border
.rounded-8 {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.rounded-12 {
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.rounded-16 {
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.rounded-end-0 {
  border-end-end-radius: 0px !important;
  border-start-end-radius: 0px !important;
}
.rounded-start-0 {
  border-start-start-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.rounded-end-16 {
  border-radius: 0px 1rem 1rem 0px;
  -webkit-border-radius: 0px 1rem 1rem 0px;
  -moz-border-radius: 0px 1rem 1rem 0px;
  -ms-border-radius: 0px 1rem 1rem 0px;
  -o-border-radius: 0px 1rem 1rem 0px;
}

// Border-Color
.border-white-10 {
  border-color: rgba($color: $white, $alpha: 0.1) !important;
}

.border-white-20 {
  border-color: rgba($color: $white, $alpha: 0.2) !important;
}

// Position
.top-24 {
  top: 1.5rem;
}
.top-12 {
  top: 0.75rem;
}
.bottom-16px {
  bottom: -16px;
}
// Shadow
.shadow-custom {
  // box-shadow: 38.0086px 43.0764px 81.0851px rgba(0, 0, 0, 0.05);
  box-shadow: 40px 44px 194px rgba(0, 0, 0, 0.05);
}

// list-circle
.list-circle {
  list-style-type: none;
  position: relative;
  padding-left: 20px;
  li {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
    &::before {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0px;
      background-image: url("./assets/images/icons/check-circle.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
    }
  }
}

// Modal
.modal {
  z-index: 2000;
}
// HeroSection
.heroSection {
  margin-top: 80px;
  ul {
    li {
      margin-bottom: 1rem;
    }
  }
}

@media (min-width: 992px) {
  .heroSection {
    margin-top: 200px;
  }
}

@media (max-width: "767.98px") {
  html {
    font-size: 90%;
  }
}

@media (max-width: "575.98px") {
  html {
    font-size: 80%;
  }
}
/* Rectangle 9559 */

.react-confirm-alert-overlay {
  background: none !important;
}