@import "./variables";
@import "~bootstrap/scss/bootstrap";

.btn {
  &.btn-secondary,
  &.btn-primary,
  &.btn-success {
    color: $white;
    &:hover {
      color: $white;
    }
  }
  &.btn-secondary {
    &.tranparent-10 {
      background-color: rgba($color: $secondary, $alpha: 0.1);
      border: none;
      color: $secondary;
    }
  }
  &.btn-blue {
    background-color: $blue;
    color: $white;
    border-color: $blue;
    &:hover {
      color: $white;
    }
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba($color: $blue, $alpha: 0.5);
    }
  }
}

.btn-transpatent-10 {
  color: $light;
  background-color: rgba($color: #d9d9d9, $alpha: 0.1) !important;
  border: 1px solid #646464 !important;

  &:hover {
    color: $light;
    background-color: rgba($color: #d9d9d9, $alpha: 0.1) !important;
    border: 1px solid #646464 !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba($color: #d9d9d9, $alpha: 0.5) !important;
  }
}
