@import "../../scss/variables";
.navbar {
  transition: 500ms all ease-in-out;
  -webkit-transition: 500ms all ease-in-out;
  -moz-transition: 500ms all ease-in-out;
  -ms-transition: 500ms all ease-in-out;
  -o-transition: 500ms all ease-in-out;
  &.navbarbg {
    background-color: $white;
    .nav-link {
      color: $black !important;
    }
    .btn-transpatent-10 {
      color: $black !important;
    }
    .navbar-toggler {
      color: $black !important;
    }
  }
  .nav-link {
    &.active {
      color: $secondary !important;
    }
  }
}
