$black: #000;
.heroSectionLayout {
  min-height: calc(100vh + 100px);
  padding-top: 80px;
  width: 100%;
  background: linear-gradient(
      to left,
      rgba($color: $black, $alpha: 0.55),
      rgba($color: $black, $alpha: 0.55)
    ),
    url("../../../assets/images/heroSection-bg.png");
  background-size: 100% 100vh;
  background-position: top center;
  background-repeat: no-repeat;
}

@media (min-width: 992px) {
  .bottomSpace {
    margin-bottom: 100px;
    .profileImgSection {
      margin-top: -100px;
      position: relative;
      top: 100px;
    }
  }
  .aboutHeroImg {
    position: absolute;
    top: -150px;
    max-width: 350px;
  }
}
